<template>
  <div class="p-grid crud-demo">
    <Dialog v-model:visible="editTicket" :style="{ width: '50vw' }" :modal="true">
      <TicketUI :id="editCard.id" @updated="list" @deleted="list" />
    </Dialog>
    <Loader v-model="loading" />
    <div class="p-col-12">
      <div class="card">
        <Toast />
        <Panel header="Administrador">
          <Toolbar class="p-mb-4">
            <template v-slot:left>
              <Button
                label="Listar"
                icon="pi pi-list"
                class="p-button-info p-mr-2"
                @click="list"
              />
              <Button
                label="Recargar"
                icon="pi pi-refresh"
                class="p-button-primary p-mr-2"
                @click="refresh"
              />
            </template>
            <template v-slot:right>
            <Button
              label="Exportar"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
          </Toolbar>
          <Fieldset :toggleable="true" legend="Parametros">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-4">
                <label>Usuario</label>
                <Dropdown
                  v-model="entity.id_tm_user"
                  :options="users"
                  optionLabel="name"
                  optionValue="id"
                />
              </div>
              <div class="p-field p-col-4">
                <label>Cliente</label>
                <Dropdown
                  v-model="entity.id_tm_customer"
                  :options="customers"
                  :loading="loadingInputs"
                  optionLabel="name"
                  optionValue="id"
                />
              </div>
              <div class="p-field p-col-4">
                <label>Proyecto</label>
                <Dropdown
                  :disabled="projects.length == 0 || loadingInputs"
                  v-model="entity.id_tm_project"
                  :options="projects"
                  optionLabel="name"
                  optionValue="id"
                />
              </div>
              <div class="p-field p-col-2">
                <label>Tag</label>
                <Dropdown
                  v-model="entity.id_tm_ticket_tag"
                  :options="tags"
                  optionLabel="name"
                  optionValue="id"
                />
              </div>
              <div class="p-field p-col-2">
                <label>Cerrados</label>
                <Dropdown
                  v-model="entity.closed"
                  :options="[
                    {
                      id: true,
                      name: 'SI',
                    },
                    {
                      id: false,
                      name: 'NO',
                    },
                  ]"
                  optionLabel="name"
                  optionValue="id"
                />
              </div>
            </div>
          </Fieldset>
          <br />
          <Fieldset :toggleable="true" legend="Resultados">
            <DataTable
              ref="dt"
              :value="entities"
              :paginator="true"
              showGridlines
              :rows="10"
              dataKey="id"
              v-model:filters="filters"
              filterDisplay="menu"
              :loading="loading"
              responsiveLayout="scroll"
              :globalFilterFields="[
                'subject',
                'user_name',
                'customer_name',
                'project_name',
                'status_name',
              ]"
            >
              <template #header>
                <div class="flex justify-content-center">
                  <Button
                    type="button"
                    icon="pi pi-filter-slash"
                    label="Limpiar"
                    class="p-button-outlined"
                    @click="clearFilter1()"
                  />
                  <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Buscar" />
                  </span>
                </div>
              </template>
              <template #empty> No se encontraron tickets. </template>
              <template #loading> Cargando tickets. </template>
              <Column filterField="subject" header="Titulo" style="min-width: 12rem">
                <template #body="{ data }">
                  <Chip
                    @click="showTicket(data)"
                    :label="data.subject"
                    icon="pi pi-envelope"
                  />
                  <Chip label="CERRADO" class="mr-2 custom-chip" v-if="data.is_closed" />
                  <Chip
                    :label="data.ticket_tag_name"
                    :style="getStyleChip(data.ticket_tag_color)"
                    v-if="data.id_tm_ticket_tag"
                  />
                </template>
                <template #filter="{ filterModel }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                  />
                </template>
              </Column>
              <Column header="Asignado" filterField="user_name" style="min-width: 12rem">
                <template #body="{ data }">
                  {{ data.user_name }}
                </template>
                <template #filter="{ filterModel }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                  />
                </template>
              </Column>
              <Column
                header="Cliente"
                filterField="customer_name"
                style="min-width: 12rem"
              >
                <template #body="{ data }">
                  {{ data.customer_name }}
                </template>
                <template #filter="{ filterModel }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                  />
                </template>
              </Column>
              <Column
                header="Proyecto"
                filterField="project_name"
                style="min-width: 12rem"
              >
                <template #body="{ data }">
                  {{ data.project_name }}
                </template>
                <template #filter="{ filterModel }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                  />
                </template>
              </Column>
              <Column header="Estatus" filterField="status_name" style="min-width: 12rem">
                <template #body="{ data }">
                  {{ data.status_name }}
                </template>
                <template #filter="{ filterModel }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                  />
                </template>
              </Column>
            </DataTable>
          </Fieldset>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
import { TMTicketTag } from "../../../models/tickets/TMTicketTag";
import { TMUser } from "../../../models/tickets/TMUser";
import { TMProject } from "../../../models/tickets/TMProject";
import { TMCustomer } from "../../../models/tickets/TMCustomer";
import { ErrorToast } from "../../../utilities/General";
import Loader from "../../../components/general/Loader.vue";
import Session from "../../../mixins/sessionMixin";
import { TMTicket } from "../../../models/tickets/TMTicket";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import TicketUI from "./Ticket.vue";

export default {
  mixins: [Session],
  data() {
    return {
      editTicket: false,
      editCard: new TMTicket(this.session),
      entity: {
        id_tm_project: null,
        id_tm_customer: null,
        id_tm_user: null,
        id_tm_ticket_tag: null,
        closed: false,
      },
      entities: [],
      users: [],
      tags: [],
      projects: [],
      projectsByCustomer: [],
      customers: [],
      filters: null,
      loading: false,
      loadingInputs: false
    };
  },
  components: { Loader, TicketUI },
  watch: {
    async ["entity.id_tm_customer"](newValue) {
      this.entity.id_tm_project = null;
      if (newValue) {
        try {
          this.loadingInputs = true;
          // * Aqui agregamos los projects by customers a partir de customer
          this.projectsByCustomer = this.projects.filter(project => project.id_tm_customer == newValue)
          //await sleep(5000)
        } catch (error) {
          this.$toast.add(new ErrorToast(error));
        } finally {
          this.loadingInputs = false;
        }
      }
    },
  },
  created() {
    this.initFilters();
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    getStyleChip(color) {
      return {
        background: "#" + color,
      };
    },
    showTicket(data) {
      this.editCard.id = data.id;
      this.editTicket = true;
    },
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        subject: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        user_name: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        customer_name: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        project_name: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        status_name: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      };
    },
    async list() {
      this.editTicket = false;
      this.loading = true;
      try {
        this.entities = await new TMTicket().data({
          customer: this.entity.id_tm_customer,
          project: this.entity.id_tm_project,
          user: this.entity.id_tm_user,
          tag: this.entity.id_tm_ticket_tag,
          closed: this.entity.closed,
        });
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    async refresh() {
      this.loading = true;
      try {
        this.tags = await new TMTicketTag().all();
        this.users = await new TMUser().all();
        this.customers = await new TMCustomer().all();
        this.entity = {
          id_tm_project: null,
          id_tm_customer: null,
          id_tm_user: null,
          id_tm_ticket_tag: null,
          closed: false,
        };
        // * Cuando necesitemos obtener info apartir de otra debemos de hacer esto
        // * yo necesito los proyectos a partir del cliente, asi que traigo todos los projects
        // * y al momento de cambiar el cliente los pongo en el ddl (Ver WATCH)
        this.projects = await new TMProject().all();
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}

.p-chip.custom-chip {
  background: var(--primary-color);
  color: var(--primary-color-text);
}
.p-chip.tag-chip {
  background: #2c9663;
  color: var(--primary-color-text);
}
</style>
